jQuery(document).ready(function ($) {
    const sliderMaterials = $('.raw-materials__body');
    if (sliderMaterials.length) {
        sliderMaterials.slick({
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            arrows: false,
            dots: true,
            responsive: [
                {
                    breakpoint: 360,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4
                    }
                },
            ]
        });
    }

    const sliderPrizePC = $('.wrapper-prize__pc');
    if (sliderPrizePC.length) {
        sliderPrizePC.slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: true,
            prevArrow: $('.wrapper-prize__arrow-left'),
            nextArrow: $('.wrapper-prize__arrow-right')
        });
    }

    const communityActivitiesWrapper = document.querySelector('.community-activities__wrapper');
    const innerWidth = window.innerWidth;
    if (communityActivitiesWrapper) {
        const communityActivitiesItems = communityActivitiesWrapper.querySelectorAll('.community-activities__item');
        if (communityActivitiesItems.length > 0) {
            communityActivitiesItems.forEach((item, index) => {
                const desc = item.querySelector('.community-activities__item--title__desc');
                let heightDesc = desc.offsetHeight;
                desc.style.height = 0;

                item.addEventListener('mouseover', () => {
                    desc.style.height = heightDesc + 'px';
                    desc.style.opacity = 1;
                    desc.style.visibility = 'visible';
                    desc.style.marginBottom = '15px';
                });

                item.addEventListener('mouseout', () => {
                    desc.style.height = 0;
                    desc.style.opacity = 0;
                    desc.style.visibility = 'hidden';
                    desc.style.marginBottom = '0';
                });

                if (innerWidth > 768) {
                    item.style.width = ((communityActivitiesWrapper.offsetWidth / 2) - 80) + 'px';

                    item.addEventListener('mouseover', () => {
                        if (index == 0) {
                            communityActivitiesItems[1].classList.remove('pending-hover');
                        } else {
                            communityActivitiesItems[0].classList.remove('pending-hover');
                        }
                        item.style.width = ((communityActivitiesWrapper.offsetWidth / 2) * 2) + 'px';
                    });

                    item.addEventListener('mouseout', () => {
                        if (index == 0) {
                            communityActivitiesItems[1].classList.add('pending-hover');
                        } else {
                            communityActivitiesItems[0].classList.add('pending-hover');
                        }
                        item.style.width = ((communityActivitiesWrapper.offsetWidth / 2) - 80) + 'px';
                    });
                }
            });
        }
    }

    document.addEventListener('click', function (e) {
        if (e.target.classList.contains('v2-list-product__panel--item') || e.target.closest('.v2-list-product__panel--item')) {
            let target = null;
            if (e.target.classList.contains('v2-list-product__panel--item')) {
                target = e.target;
            } else {
                target = e.target.closest('.v2-list-product__panel--item')
            }

            let dataId = target.getAttribute('data-id');

            const bodyItemActive = document.querySelector('.v2-list-product__body--item.active');
            if (bodyItemActive) {
                bodyItemActive.classList.remove('active');
            }

            const bodyItem = document.querySelector('.v2-list-product__body--item[data-id="' + dataId + '"]');
            if (bodyItem) {
                bodyItem.classList.add('active');
            }

            const panelItemActive = document.querySelector('.v2-list-product__panel--item.active');
            if (panelItemActive) {
                panelItemActive.classList.remove('active');
            }

            target.classList.add('active');
        }
    })

    const sliderBlogs = $('.v2-home-blogs');
    if (sliderBlogs) {
        sliderBlogs.slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: true,
            prevArrow: $('.box-v2-home-blogs__arrow-left'),
            nextArrow: $('.box-v2-home-blogs__arrow-right'),
            dots: false,
            responsive: [
                {
                    breakpoint: 360,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
            ]
        });
    }

    $(window).on('scroll', function (e) {
        function numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        if ($(window).scrollTop() >= ($(".goto").offset().top - ($(window).height()))) {
            if (!$(".goto").hasClass("animated")) {
                $('.count').each(function () {
                    $(this).prop('Counter', 0).animate({
                        Counter: $(this).text()
                    }, {
                        duration: 1500,
                        easing: 'swing',
                        step: function (now) {
                            $(this).text(numberWithCommas(Math.ceil(now)) + '%');
                        }
                    });
                });
                // $("#triggered").addClass("show");
                $(".goto").addClass("animated");
            }
        }
    });

    const headerMenuItems = document.querySelectorAll('.header-menu__item ');
    console.log(headerMenuItems);

    if (headerMenuItems.length) {
        headerMenuItems.forEach(item => {
            const menuParent = item.closest('.menu-parent');

            if (menuParent) {
                const dataHasMega = menuParent.getAttribute('data-has-mega');
                const menuItemFirst = menuParent.querySelector('.header-menu__item');
                menuParent.addEventListener('mouseover', (e) => {
                    if (e.target.classList.contains('menu-parent')) {
                        const megaMenu = menuItemFirst.querySelector('.megaMenu');
                        if (megaMenu) {
                            megaMenu.style.display = 'flex';
                            megaMenu.style.opacity = 'unset';
                        }
                    }
                })
                if (dataHasMega == 'true') {
                    item.addEventListener('mouseover', () => {
                        if (menuItemFirst != item) {
                            const megaMenu = menuItemFirst.querySelector('.megaMenu');
                            console.log(megaMenu);

                            if (megaMenu) {
                                megaMenu.style.opacity = '0';
                            }
                        } else {
                            const megaMenu = item.querySelector('.megaMenu');
                            if (megaMenu) {
                                megaMenu.style.display = 'flex';
                                megaMenu.style.opacity = 'unset';
                            }
                        }
                    });
                }
            }
        });
    }
});